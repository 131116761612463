/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit_more_actions_light': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g _fill="#F9F9F9" fill-rule="nonzero"><path pid="0" d="M6.505 3a.5.5 0 010 1H2.5A1.5 1.5 0 001 5.5v7.998a1.5 1.5 0 001.5 1.5h8.005a1.5 1.5 0 001.5-1.5V10.5a.5.5 0 111 0v2.998a2.5 2.5 0 01-2.5 2.5H2.5a2.5 2.5 0 01-2.5-2.5V5.5A2.5 2.5 0 012.5 3h4.005z"/><path pid="1" d="M6.512 9.848L8 9.728l5.9-5.9-1.415-1.414-5.889 5.89-.084 1.544zM5.89 7.596l5.89-5.889a1 1 0 011.413 0l1.415 1.414a1 1 0 010 1.415l-5.9 5.9a1 1 0 01-.627.289l-2.629.212.147-2.688a1 1 0 01.291-.653z"/></g>'
  }
})
